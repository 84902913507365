import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';





const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',

    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        width: 300,
        height: 50
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing.unit,
        width: 200,
        height: 50
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        height: 50
    },

});

class SimpleSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            labelWidth: 0,
            file: null,
            fileName: 'No file selected',
            loading: false,
            status: "Uploading file",
            uploadFinished: false,
            marketid: '0000000',
        };
    }



    componentDidMount() {
        this.setState({
            //labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });

    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeMarket = event => {
        this.setState({ marketid: event.target.value });
    }


    handelOnUploadFile = (event) => {
        this.setState({
            file: event.target.files[0],
            fileName: event.target.files[0].name,
        })

        console.log(event.target.files[0]);
    }

    handleSubmit = (event) => {
        let file = this.state.file;
        let formdata = new FormData();
        formdata.append('fileToUpload', file);

        this.setState({
            loading: true,
            status: "Uploading file"
        });

        let marketid = '';
        if (this.props.username === '9530000') marketid = this.state.marketid;

        axios.post('https://pmeparts.com/backend/routes/upload.php?id=' + this.props.username +
            '&password=' + this.props.password + '&market_id=' + marketid, formdata)
            .then(response => {
                // handle success
                console.log(response.data.file);

                this.loadInDB(response.data.file);

                this.setState({
                    loading: true,
                    status: "Loading data in database"
                });
            })
            .catch(function (error) {
                // handle error
                console.log(error);

            })
            .then(function () {
                // always executed
            });
    }

    loadInDB(fileName) {

        let marketid = '';
        if (this.props.username === '9530000') marketid = this.state.marketid;

        axios.post('https://pmeparts.com/backend/routes/loadInDB.php?id=' + this.props.username +
            '&password=' + this.props.password + '&file=' + fileName + '&market_id=' + marketid)
            .then(response => {
                // handle success
                console.log(response.data);
                this.setState({
                    uploadFinished: true,
                    status: "Database successfully updated!"
                });
            })
            .catch(function (error) {
                // handle error
                console.log(error);

            })
            .then(function () {
                // always executed
            });
    }


    render() {
        const { classes } = this.props;

        return (
            <form className={classes.root} autoComplete="off">
                {!this.state.loading ?
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "auto", marginRight: "auto" }}>
                        {this.props.username !== "9530000" ? (
                            <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: 160 }}>

                                <InputLabel
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                    htmlFor="outlined-age-simple"
                                >
                                    Market: {this.props.username}
                                </InputLabel>
                                {/* <Select
                            value={this.state.age}
                            onChange={this.handleChange}
                            input={
                                <OutlinedInput
                                    labelWidth={this.state.labelWidth}
                                    name="age"
                                    id="outlined-age-simple"
                                />
                            }
                        >
                            <MenuItem value={10}>Dubai</MenuItem>
                            <MenuItem value={20}>Abu Dhabi</MenuItem>
                            <MenuItem value={30}>Oman</MenuItem>
                            <MenuItem value={30}>Qatar</MenuItem>
                            <MenuItem value={30}>India</MenuItem>
                            <MenuItem value={30}>Pakistan</MenuItem>
                            <MenuItem value={30}>Jordan</MenuItem>
                            <MenuItem value={30}>Egypt</MenuItem>
                            <MenuItem value={30}>Morocco</MenuItem>
                            <MenuItem value={30}>La Reunion</MenuItem>
                            <MenuItem value={30}>Kenya</MenuItem>
                        </Select> */}
                            </FormControl>) : ''}
                        {this.props.username === "9530000" ? (
                            <TextField
                                id="standard-with-placeholder"
                                label="Market ID"
                                placeholder="Market ID"
                                className={classes.textField}
                                margin="normal"
                                value={this.state.marketid}
                                onChange={this.handleChangeMarket}
                            />) : ''}

                        <TextField
                            disabled
                            id="outlined-disabled"
                            //defaultValue={this.state.fileName}
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            //fullWidth
                            value={this.state.fileName}
                        />
                        <input
                            accept=".csv"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={this.handelOnUploadFile}
                            multiple
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span" size="large" className={classes.button}>
                                Select File
                        </Button>
                        </label>
                        <Button variant="contained" component="span" size="large" color="secondary" className={classes.button} onClick={this.handleSubmit}
                            disabled={this.state.file == null ? true : false}
                        >
                            Upload
                        <CloudUploadIcon className={classes.rightIcon} />

                        </Button>
                    </div>
                    : <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "auto", marginRight: "auto" }}>
                        {this.state.uploadFinished ? null : <CircularProgress className={classes.progress} />}
                        <Typography variant="h6" >
                            {this.state.status}
                        </Typography>
                    </div>
                }


            </form>

        );
    }
}

SimpleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
