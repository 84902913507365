import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchPage from './SearchPage';
import SearchIcon from '@material-ui/icons/Search';
import UploadIcon from '@material-ui/icons/CloudUpload';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { Switch, Route, Link } from 'react-router-dom'
import UploadComponent from './UploadComponent';


const drawerWidth = 300;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
});


function DashboardComponent(props) {
    const { classes } = props;

    const username = props.username;
    const password = props.password;
    const admin = props.admin;


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        PME Parts Locator
          </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >

                <Divider />
                <List>
                    <ListItem button component={Link} to="/search">
                        <ListItemIcon>
                            <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary="Search part" />
                    </ListItem>
                    <ListItem button component={Link} to="/upload">
                        <ListItemIcon>
                            <UploadIcon />
                        </ListItemIcon>
                        <ListItemText primary="Upload parts stock" />
                    </ListItem>
                    <ListItem button component={Link} to="/" onClick={() => { localStorage.clear(); window.location.href = '/' }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route exact path='/search'
                        render={(props) => <SearchPage {...props} username={username}
                            password={password}
                            admin={admin} />}
                    />
                    <Route exact path='/upload'
                        render={(props) => <UploadComponent {...props} username={username}
                            password={password}
                            admin={admin} />}
                    />                </Switch>
            </main>
        </div>
    );
}

DashboardComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardComponent);