import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DashboardComponent from './DashboardComponent';
import SignIn from './SignIn';
import axios from 'axios';


const theme = createMuiTheme({
    palette: {
        primary: { main: "#494e51" },
        secondary: { main: "#D5001C" },
    },
    status: {
        danger: 'orange',
    },
    typography: {
        useNextVariants: true,
    },
});


export class ContentComponent extends Component {
    static propTypes = {

    }

    handleLogin = (username, password) => {
        // Make a request for a user with a given ID
        axios.get('https://pmeparts.com/backend/routes/auth.php?id=' + username + '&password=' + password)
            .then(response => {
                // handle success
                console.log(response);

                if (response.data.auth === true) {
                    this.updateState(response.data);

                    this.saveStateToLocalStorage();

                } else {
                    this.wrongPW();
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);

            })
            .then(function () {
                // always executed
            });
    }

    updateState = data => {
        this.setState({
            user: data.username,
            password: data.password,
            admin: data.admin,
        })
    }

    wrongPW() {
        this.setState({ message: "Wrong username or password" });
        alert("Wrong username and/or password");
    }

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            password: null,
            admin: false,
            message: "",
        };

    }

    hydrateStateWithLocalStorage() {
        // for all items in state
        for (let key in this.state) {
            // if the key exists in localStorage
            if (localStorage.hasOwnProperty(key)) {
                // get the key's value from localStorage
                let value = localStorage.getItem(key);

                // parse the localStorage string and setState
                try {
                    value = JSON.parse(value);
                    this.setState({ [key]: value });
                } catch (e) {
                    // handle empty string
                    this.setState({ [key]: value });
                }
            }
        }
    }

    saveStateToLocalStorage() {
        // for every item in React state
        for (let key in this.state) {
            // save to localStorage
            localStorage.setItem(key, JSON.stringify(this.state[key]));
        }
    }


    componentDidMount() {
        this.hydrateStateWithLocalStorage();
    }


    render() {
        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    {/* <DashboardComponent /> */}
                    {(this.state.user == null && this.state.password == null) ?
                        <SignIn
                            handleLogin={this.handleLogin}
                            message={this.state.message}
                        /> :
                        <DashboardComponent
                            username={this.state.user}
                            password={this.state.password}
                            admin={this.state.admin} />
                    }
                </MuiThemeProvider>
            </div>
        )
    }
}

export default ContentComponent
