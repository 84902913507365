import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
});

let id = 0;
function createData(market, partno, quantity) {
    id += 1;
    return { market, partno, quantity }
}





function ResultTable(props) {
    const { classes } = props;

    console.log(props.data);


    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Market</TableCell>
                        <TableCell>Part number</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Last update</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(props.data) ? props.data.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.m_id}
                                </TableCell>
                                <TableCell>{row.part_no}</TableCell>
                                <TableCell>{row.quantity}</TableCell>
                                <TableCell>{row.last_update}</TableCell>
                            </TableRow>
                        );
                    }) : null}
                </TableBody>
            </Table>
        </Paper>
    );
}

ResultTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResultTable);