import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SearchField from './SearchField';
import ResultTable from './ResultTable';
import axios from 'axios';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});


class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { requestData: [{ part_no: "--", m_id: "--", quantity: "--" }] };
        this.executeSearch = this.executeSearch.bind(this);

    }

    updateState = newState => {
        this.setState({ requestData: newState })
    }



    executeSearch = (partnumber) => {
        // Make a request for a user with a given ID
        axios.get('https://pmeparts.com/backend/routes/search.php?id=' + this.props.username +
            '&password=' + this.props.password + '&search=' + partnumber)
            .then(response => {
                // handle success
                console.log(response.data);
                this.updateState(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);

            })
            .then(function () {
                // always executed
            });
    }



    render() {
        const { classes } = this.props;

        return (
            <div>
                <SearchField executeSearch={this.executeSearch} />
                <ResultTable data={this.state.requestData} />
            </div>
        );
    }
}



export default withStyles(styles)(SearchPage);
