import "@babel/polyfill";
import React, { Component } from 'react';
import { ContentComponent } from "./components/ContentComponent";
import { BrowserRouter } from 'react-router-dom'
import { polyfill } from 'es6-promise';
polyfill();



class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <ContentComponent />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
